<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Ingate</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-text-field
                    label="Login"
                    name="login"
                    v-model="user.login"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    v-model="user.password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>

                  <v-text-field
                    id="code"
                    label="code"
                    name="code"
                    v-model="user.code"
                    prepend-icon="mdi-lock-reset"
                    type="text"
                  ></v-text-field>
                  <input
                    type="submit"
                    style="position: absolute; left: -9999px"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="login" color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import User from "@/models/user";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: new User("", "", ""),
      image_data: false
    };
  },
  computed: {
    ...mapGetters("user", ["auth"])
  },
  created() {
    if (this.auth) {
      this.$router.push("/");
    }
  },
  methods: {
    login() {
      this.$store.dispatch("user/login", this.user).then(
        () => {
          this.$router.push("/");
        },
        error => {
          this.$noty.error(
            `Ошибка авторизации: ${error.response.data.message}`
          );
        }
      );
    }
  }
};
</script>
